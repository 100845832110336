import {
  StatisticsBoxContext,
  StatisticsBoxType,
  StatRecord,
} from '@/graphql/generated/types';
import { format, parseISO } from 'date-fns';
import { getGraphTypes } from './shared/box/statistics-box-registry';
import { ChartPoint } from './shared/chart/chart.component';
import { ChartOptions } from './shared/chart/options/options.provider';

const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mär',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dez',
];

export enum TimespanFormat {
  Day = 'yyyy-MM-dd',
  Week = 'yyyy-ww',
  Month = 'yyyy-MM',
  Year = 'yyyy',
}

function formatTimeLabel(input: string, timespan: TimespanFormat) {
  switch (timespan) {
    case TimespanFormat.Day:
      return format(parseISO(input), 'dd.MM.y');
    case TimespanFormat.Week:
      return `KW ${input.substring(5, 9)}/${input.substring(0, 4)}`;
    case TimespanFormat.Month:
      const numericMonth = parseInt(input.substring(5, 7));
      return `${MONTH_NAMES[numericMonth - 1]} ${input.substring(0, 4)}`;
    case TimespanFormat.Year:
      return input;
  }
}

export function transformRecordsToGraphData(
  data: StatRecord[],
  type: StatisticsBoxType,
  context: StatisticsBoxContext,
  options: ChartOptions,
) {
  if (!options.graphs) {
    return [];
  }
  const timeRecords: Record<string, ChartPoint> = {};

  const graphTypes = getGraphTypes(type, context);

  for (const record of data) {
    const time = format(parseISO(record.createdAt), options.timespan);

    if (!timeRecords[time]) {
      timeRecords[time] = {
        time: formatTimeLabel(time, options.timespan),
      };
    }
    for (let i = 0; i < options.graphs.length; i++) {
      if (!timeRecords[time][`value${i}`]) {
        timeRecords[time][`value${i}`] = 0;
      }
      const graph = options.graphs[i];
      const filterCallback = graphTypes.find(
        (t) => t.value === graph.type,
      )?.filterCallback;
      const incrementCallback = graphTypes.find(
        (t) => t.value === graph.type,
      )?.incrementCallback;

      if (filterCallback && filterCallback(record, graph.options)) {
        const newValue = incrementCallback
          ? incrementCallback(
              timeRecords[time][`value${i}`] as number,
              record,
              options,
            )
          : (timeRecords[time][`value${i}`] as number) + 1;
        timeRecords[time][`value${i}`] = newValue;
      }
    }
  }
  return Object.values(timeRecords);
}
