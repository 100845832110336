import { FilterLeadsInput, LeadAction } from '@/graphql/generated/types';
import { Button } from '@/ui/button/button.component';
import { DatetimeField } from '@/ui/datetime-field/datetime-field.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { ListView } from '@/ui/list-view/list-view.component';
import { SelectField2 } from '@/ui/select-field-2/select-field-2.component';
import { Row } from '@/ui/table/row.component';
import { Link, RouteComponentProps } from '@reach/router';
import { format } from 'date-fns';
import { pick } from 'lodash-es';
import { stringify } from 'query-string';
import React, { ComponentType } from 'react';
import { API_URI } from 'shared/constants';
import { useUrlQuery } from 'shared/hooks/url-query';
import { Translator } from 'shared/translator/translator.component';
import { Frame } from '../../frame';
import { LeadsQuery, useLeadsQuery } from './list.generated';

export const List: ComponentType<RouteComponentProps> = (props) => {
  const [query] = useUrlQuery<FilterLeadsInput>();
  const leadsQuery = useLeadsQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: pick(query, 'startDate', 'endDate', 'actions'),
    },
  });

  const _ = Translator.useTranslator();

  return (
    <>
      <Frame.SubTitle>Übersicht</Frame.SubTitle>

      <Frame.ActionBar>
        <Button
          href={`${API_URI}/export/v2/leads?${stringify({
            startDate: query.startDate,
            endDate: query.endDate,
          })}`}
          //disabled={!query.startDate || !query.endDate}
          primary
          label="CSV Export"
        />
      </Frame.ActionBar>
      <Frame.Content>
        <ListView<LeadsQuery['leads'][number], any>
          loading={leadsQuery.loading}
          data={leadsQuery.data?.leads || []}
          columns={[
            {
              name: 'Erstellt am',
              sortable: true,
            },
            { name: 'Person', sortable: true },
            { name: 'Aktion', sortable: true },
          ]}
          renderFilter={({ value, onChange }) => (
            <>
              <SelectField2
                multiple
                label="Nach Aktionen filtern"
                value={value.actions}
                choices={[
                  {
                    value: LeadAction.Order,
                    label: _('lead-action.Order'),
                  },
                  {
                    value: LeadAction.Booking,
                    label: _('lead-action.Booking'),
                  },
                  {
                    value: LeadAction.Inquiry,
                    label: _('lead-action.Inquiry'),
                  },
                  {
                    value: LeadAction.ConsultingEvent,
                    label: _('lead-action.ConsultingEvent'),
                  },
                ]}
                onChange={(value) => onChange('actions', value)}
              />
              <DatetimeField
                white
                label="Anfangs-Datum"
                value={value.startDate}
                withTime={false}
                onChange={(value) => {
                  onChange(
                    'startDate',
                    value ? format(value, 'yyyy-MM-dd') : null,
                  );
                }}
              />
              <DatetimeField
                white
                label="End-Datum"
                value={value.endDate}
                withTime={false}
                onChange={(value) =>
                  onChange(
                    'endDate',
                    value ? format(value, 'yyyy-MM-dd') : null,
                  )
                }
              />
            </>
          )}
          renderRow={(item, index) => {
            let actionLink = '';
            if (item.action === LeadAction.Order) {
              actionLink = `/orders/${item.actionId}/view`;
            } else if (item.action === LeadAction.Booking) {
              actionLink = `/course-handling/bookings/${item.actionId}/view`;
            } else if (item.action === LeadAction.Inquiry) {
              actionLink = `/inquiries/${item.actionId}/edit`;
            } else {
              actionLink = `/event-management/events/${item.actionId}/detail`;
            }
            return (
              <Row key={index}>
                <td>
                  <Link to={actionLink}>
                    <Formatted.Date value={item.createdAt} withTime />
                  </Link>
                </td>
                <td>
                  <Link to={actionLink}>
                    {[
                      item.person.title,
                      item.person.firstName,
                      item.person.lastName,
                    ].join(' ')}
                  </Link>
                </td>
                <td>
                  <Link to={actionLink}>
                    {item.action === LeadAction.Order && 'Zur Bestellung'}
                    {item.action === LeadAction.Booking && 'Zur Buchung'}
                    {item.action === LeadAction.Inquiry && 'Zur Anfrage'}
                    {item.action === LeadAction.ConsultingEvent &&
                      'Zum Beratungstermin'}
                  </Link>
                </td>
              </Row>
            );
          }}
        />
      </Frame.Content>
    </>
  );
};
