import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { format } from 'date-fns';
import React, { ComponentType } from 'react';
import { Frame } from '../frame';
import { List } from './list/list.component';

export const Lead: ComponentType<RouteComponentProps> = (props) => {
  const today = format(new Date(), 'yyyy-MM-dd');
  return (
    <>
      <Frame.Title>Leads</Frame.Title>
      <Router className="router">
        <List path="list" />

        <Redirect
          noThrow
          from="/"
          to={`list?startDate=${today}&endDate=${today}`}
        />
      </Router>
    </>
  );
};
