import { TimespanFormat } from '@/statistics-v3/statistics';
import { DatetimeField } from '@/ui/datetime-field/datetime-field.component';
import { SelectField } from '@/ui/select-field/select-field.component';

import { format } from 'date-fns';
import React, { ComponentType } from 'react';
import { Translator } from 'shared/translator/translator.component';
import styles from '../../../shared/box/statistics-box.module.scss';
import { useChartOptions } from '../../../shared/chart/options/options.provider';

export const OrderChartFilter: ComponentType = (props) => {
  const _ = Translator.useTranslator();
  const { options, setOptions } = useChartOptions();

  return (
    <div className={styles.filter}>
      <DatetimeField
        small
        inline
        white
        label="Anfangs-Datum"
        value={options.startDate}
        withTime={false}
        onChange={(value) => {
          setOptions({
            ...options,
            startDate: value ? format(value, 'yyyy-MM-dd') : '',
          });
        }}
      />
      <DatetimeField
        white
        inline
        small
        label="End-Datum"
        value={options.endDate}
        withTime={false}
        onChange={(value) => {
          setOptions({
            ...options,
            endDate: value ? format(value, 'yyyy-MM-dd') : '',
          });
        }}
      />
      <SelectField
        label="Aufschlüsseln"
        small
        value={options.timespan}
        choices={Object.entries(TimespanFormat).map(([key, value]) => ({
          label: _(`TimespanFormat.${key}`),
          value: value,
        }))}
        onChange={(value) =>
          setOptions({ ...options, timespan: value as TimespanFormat })
        }
      />

      <SelectField
        label="Eigenschaft"
        small
        value={options.property}
        choices={[
          {
            value: 'quantity',
            label: 'Anzahl',
          },
          { value: 'amount', label: 'Umsatz' },
        ]}
        onChange={(value) => {
          setOptions({ ...options, property: value as string });
        }}
      />
    </div>
  );
};
